/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */
import React, { useEffect, useState } from 'react';
import { UeContainer, UeReference } from '@oneaudi/falcon-tools';
import { createTrackingService } from '@volkswagen-onehub/oneaudi-os-adapter-tracking';

import type { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import type { FeatureAppProps } from '../@types/interfaces/FeatureApp';
import type {
  InterfaceContentHeadless,
  InterfaceContentNeMo,
} from '../@types/interfaces/adapterCMSEditor';

import { GenerateIframe } from './components/GenerateIframe';
import { convertCMSContentToIframeProps } from './convertCMSContentToIframeProps';

export const FeatureApp: React.FC<FeatureAppProps> = (props: FeatureAppProps) => {
  const { contentService, trackingService: _trackingService } = props;
  let trackingService = {} as TrackingServiceV2;
  if (typeof window !== 'undefined') {
    trackingService =
      _trackingService ||
      (createTrackingService(__FEATURE_APP_NAME__) as unknown as TrackingServiceV2);
  }

  const [content, setContent] = useState(
    contentService?.getContent() as InterfaceContentHeadless | InterfaceContentNeMo,
  );

  useEffect(() => {
    trackingService.featureAppName = __FEATURE_APP_NAME__;
    const readyEvent = {
      event: {
        attributes: {
          componentName: __FEATURE_APP_NAME__,
          elementName: '',
          label: '',
          clickID: '',
          pos: '',
          targetURL: '',
          value: '',
        },
        eventInfo: {
          eventAction: 'feature_app_ready',
          eventName: `${__FEATURE_APP_NAME__} - Feature App ready`,
        },
      },
    };
    trackingService.track(readyEvent);
  }, []);

  useEffect(() => {
    contentService?.onContentChange(() => {
      setContent(contentService?.getContent() as InterfaceContentHeadless | InterfaceContentNeMo);
    });
  }, [content]);

  const { iframeProps, presetIframe, presetSizing, analytics } =
    convertCMSContentToIframeProps(content);
  return (
    <UeContainer propertyPath="iFrame">
      <UeReference propertyPath="iFrame" behavior="component">
        {(ueProps) => (
          <GenerateIframe
            trackingService={trackingService}
            presetIframe={presetIframe}
            presetSizing={presetSizing}
            iframeProps={iframeProps}
            analytics={analytics}
            {...ueProps}
          />
        )}
      </UeReference>
    </UeContainer>
  );
};

export default FeatureApp;
